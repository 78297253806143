import { LitElement, html, css } from 'lit';
import { property, customElement, state } from 'lit/decorators.js';
import { Ayetu } from '@ayetu/sdk-js';

@customElement('contact-remove')
export class ContactRemove extends LitElement {
  static styles = css`
    .contact-remove {
      width: 100%;
      margin: 4px 2px;
      position: relative;
    }
    .contact-remove--input {
      width: 100%;
      height: 34px;
      border-radius: 5px;
      border: 1px solid #e9e9e9;
      text-indent: 12px;
      box-sizing: border-box;
      color: #505b67;
      background-color: #ebeced;
      margin-bottom: 10px;
    }
    .remove-button {
      display: block;
      width: 100%;
      height: 34px;
      border-radius: 5px;
      background-color: #4caf50;
      color: white;
      border: none;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
    }
    .remove-button:hover {
      background-color: #45a049;
    }
  `;

  @property({ type: String })
  contactId: string = '';

  @property({ type: Boolean })
  debugMode: boolean = false;

  handleInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.contactId = input.value;
    if (this.debugMode) {
      console.log(`Contact ID changed to: ${this.contactId}`);
    }
  }

  async handleRemoveContact() {
    if (this.debugMode) {
      console.log(`Removing contact with ID: ${this.contactId}`);
    }

    try {
      const result = await Ayetu.contact.remove(this.contactId);
      if (result.success) {
        console.log('Contact removed successfully');
      } else {
        console.error('Failed to remove contact:', result.error);
      }
    } catch (error) {
      console.error('Error removing contact:', error);
    }
  }

  render() {
    return html`
      <div class="contact-remove">
        <input
          class="contact-remove--input"
          type="text"
          .value=${this.contactId}
          @input=${this.handleInputChange}
          placeholder="Enter user ID"
        />
        <button class="remove-button" @click=${this.handleRemoveContact}>Remove Contact</button>
      </div>
    `;
  }
}
